<template>
  <card
    title="Consignments sharing rules (for internal shipments only)"
    title-class=""
    bodyClass="p-0"
    class="rules-mapping border-radius table-page__thead--sticky"
  >
    <template slot="tools">
      <div class="flex">
        <button v-if="isGodUser" class="btn" @click="recalculate">Recalculate visibility for all consignments</button>
        <button
            v-if="isAllowedToEdit"
            type="button"
            :disabled="!hasChanges || hasNewRules || !isAllRowsValid || isLoading"
            class="btn ml-2"
            :class="{ 'btn-loading': isLoading }"
            @click="saveChanged"
        >
            Save
        </button>
        <v-columns-switch
            v-model="toggleableColumns"
            :toggle-table-tools="isShowColumnsSwitch"
            @click="columnsSwitchUpdated"
            @show="isShowColumnsSwitch = $event"
            @update="updateColumnCheckbox"
            class="ml-2"
        />
      </div>
    </template>

    <div class="w-full">
        <v-table
            :columns="columns"
            :tools="false"
            :editable="true"
            row-key="id"
            :ref="tableRef"
            :endpoint="endpoint"
            :filter-params="{ 'filter[owner_from_source]': organizationKey }"
            sort-key="priority"
            :sort-order="1"
            no-cache
            static
            class="mb-2"
            :on-rows-loaded="onRowsLoaded"
            :on-draggable-rows-set="onDraggableRowsSet"
            @draggable-row-change="onDraggableRowChange"
            :paginate-value="1000"
        >
            <template slot="cell" slot-scope="{row, col}">
                <span v-if="dateFields.includes(col.key)" class="rules-table-span">
                    {{ formatDate(row[col.key]) }}
                </span>
                <div v-else-if="['is_active', 'show_price_for_partner', 'show_documents_for_partner'].includes(col.key)">
                    <div v-if="!isAllowedToEdit" class="flex h-10">
                        <v-checkbox :value="row[col.key]" disabled />
                    </div>

                    <div v-else class="flex h-10">
                        <v-checkbox v-model="row[col.key]" @click="updateHasChanges" />
                        <button v-if="col.key === 'is_active'" class="ml-2" @click="openRemoveModal(row.id)">
                            <svg-importer icon-name="icons/close" />
                        </button>
                    </div>
                </div>
                <div
                    v-else-if="col.key === 'owner_site_from_source'"
                    :key="`wrapper_${col.key}-${componentCounter}`"
                    :class="col.width"
                >
                    <input v-if="!isAllowedToEdit" type="text" :value="row[col.key]" disabled />
                    <multiselect
                        v-else
                        v-model="row[col.key]"
                        :options="ownerSitesOptions"
                        open-direction="bottom"
                        :class="{'input-error': !getIsValidRow(row) && getIsEmptyValue(row[col.key])}"
                        @select="updateHasChanges"
                        @search-change="updateField({ row, key: col.key, value: $event })"
                    />
                </div>
                <div v-else-if="col.key === 'owner_from_source'" :class="col.width" class="flex">
                    <span class="rules-table-span">{{ row[col.key] }}</span>
                </div>
                <div
                    v-else-if="col.key === 'payer'"
                    :key="`wrapper_${col.key}-${componentCounter}`"
                    :class="col.width"
                >
                    <multiselect
                        v-if="isAllowedToEdit"
                        v-model="row[col.key]"
                        :options="payerOptions"
                        open-direction="bottom"
                        :class="{'input-error': !getIsValidRow(row) && getIsEmptyValue(row[col.key])}"
                        @select="updateHasChanges"
                    />
                    <input v-else type="text" :value="row[col.key]" disabled />
                </div>
                <div v-else-if="col.key === 'partner_organization_key'">
                    <multiselect
                        v-if="isAllowedToEdit"
                        v-model="row[col.key]"
                        :options="partnerOrganizationOptions"
                        open-direction="bottom"
                        @select="selectPartnerOrganization(row, $event)"
                        @search-change="updateField({ row, key: col.key, value: $event })"
                    />
                    <input v-else type="text" :value="row[col.key]" disabled />
                </div>
                <div v-else-if="col.key === 'partner_site_key'">
                    <multiselect
                        v-if="isAllowedToEdit"
                        v-model="row[col.key]"
                        :options="partnerSiteOptions"
                        open-direction="bottom"
                        @select="selectPartnerSite(row, $event)"
                        @search-change="updateField({ row, key: col.key, value: $event })"
                    />
                    <input v-else type="text" :value="row[col.key]" disabled />
                </div>
                <div v-else-if="getIsAutocompleteField(col.key) && isAllowedToEdit">
                    <autocomplete
                        :key="`autocomplete_${col.key}_index`"
                        v-model="row[col.key]"
                        :endpoint="filterOptionsUrl({ role: col.key.split('_')[0], field: col.key.split('_')[1] })"
                        :search-trigger-chars-threshold="2"
                        :class="{'input-error': !getIsValidRow(row) && getIsEmptyValue(row[col.key])}"
                        @click="updateField({ row, key: col.key, value: $event })"
                    />
                </div>
                <div v-else-if="col.key === 'priority'" class="flex">
                    <button class="handle mr-2" @mousedown="isDraggingId = row.id">
                        <fa :icon="['fal','grip-lines']" style="width: 20px; height: 20px;" />
                    </button>
                    <span class="rules-table-span">{{ row[col.key] }}</span>
                </div>
                <div v-else :class="col.width">
                    <input
                        v-if="!isAllowedToEdit"
                        type="text"
                        disabled
                        :value="row[col.key]"
                    />
                    <input
                        v-else
                        :key="`input_${col.key}_index`"
                        type="text"
                        :value="row[col.key]"
                        placeholder="Enter value"
                        @blur="updateField({ row, key: col.key, value: $event.target.value })"
                    />
                </div>
            </template>
        </v-table>
        <template v-if="isAllowedToEdit">
            <button v-show="!hasNewRules" :disabled="!hasNewRules && hasChanges" type="button" class="btn w-full" @click="addEmptyRow">
                +Add new rule
            </button>
            <button
                v-show="hasNewRules"
                :disabled="(!hasNewRules && hasChanges) || isNewRowEmpty || isLoadingNew"
                type="button"
                class="btn w-full"
                :class="{ 'btn-loading': isLoadingNew }"
                @click="saveNewRule"
            >
                Save new rule
            </button>
        </template>
    </div>
    <delete-modal
        :show="isShowRemoveModal"
        name="consignment sharing rule"
        @hide="closeRemoveModal"
        @delete="removeRule"
    />
  </card>
</template>
<script>
import axios from 'axios';
import moment from 'moment';
import columns from './consignmentsSharingColumns.json';
import Autocomplete from '~/components/Autocomplete';
import Multiselect from 'vue-multiselect'
import VCheckbox from '~/components/VCheckbox.vue';
import { requestErrors } from '~/utils/errors';
import VColumnsSwitch from '@/components/vtable/VColumnsSwitch.vue';
import DraggableRowsMixin from '@/mixins/draggablePriority.mixin';
import MappingMixin from '~/mixins/mappingTable.mixin';
import ToggleableColumnsMixin from '@/components/vtable/toggleableColumns.mixin.js';

const DEFAULT_ROW = {
    priority: 0,
    owner_from_source: null,
    owner_site_from_source: null,
    partner_organization_key: null,
    partner_site_key: null,
    partner_site_options: [],
    show_price_for_partner: 0,
    show_documents_for_partner: 0,
    carrier_from_source: '',
    consignor_name: '',
    consignor_countrycode: '',
    consignor_postcode: '',
    consignee_name: '',
    consignee_countrycode: '',
    consignee_postcode: '',
    pickup_name: '',
    pickup_countrycode: '',
    pickup_postcode: '',
    pickup_city: '',
    delivery_name: '',
    delivery_countrycode: '',
    delivery_postcode: '',
    delivery_city: '',
    payer: '',
    is_active: 0,
};

const DEFAULT_OPTION = '*';

const AUTOCOMPLETE_FIELD_NAMES = ['countrycode', 'postcode', 'city', 'name'];

const PAYLOAD_IGNORE_FIELDS = ['id', 'updated_at', 'created_at'];
const UNTOGGLEABLE_COLUMNS_KEYS = ['is_active', 'partner_organization_key', 'partner_site_key', 'show_price_for_partner'];
const VALIDATE_IGNORE_FIELDS = [...PAYLOAD_IGNORE_FIELDS, ...UNTOGGLEABLE_COLUMNS_KEYS, 'priority', 'owner_from_source'];

const preparePayloadRow = (row) => {
    const rule = {};

    Object.entries(row).forEach(([key, value]) => {
        if (PAYLOAD_IGNORE_FIELDS.includes(key)) {
            return;
        }

        if (key === 'priority') {
            rule[key] = +row[key];

            return;
        }

        rule[key] = ['show_price_for_partner', 'is_active', 'show_documents_for_partner'].includes(key) ? value : value || DEFAULT_OPTION;
    });

    return rule;
};

const validateRow = (row) => {
    return Object.entries(row)
        .reduce((isValid, entry) => {
            if (VALIDATE_IGNORE_FIELDS.includes(entry[0])) {
                return isValid;
            }

            const value = entry[1];

            if (!value || value === DEFAULT_OPTION) {
                return isValid;
            }

            if (Array.isArray(value) || !value.length) {
                return isValid;
            }

            isValid = true;

            return isValid;
        }, false);
};

export default {
    components: {
        Autocomplete,
        VCheckbox,
        VColumnsSwitch,
        Multiselect,
    },
    mixins: [ToggleableColumnsMixin, MappingMixin, DraggableRowsMixin],
    props: {
        organization: {
            type: Object,
            default: null,
        },
        organizationKey: {
            type: String,
            default: '',
        },
    },
    data() {
        return {
            tableRef: 'consignmentsSharing',
            componentCounter: 0,
            columns,
            data: [],
            endpoint: this.$apiUrl.consignmentImports.rules.share.base,
            hasNewRules: false,
            currentRemoveId: null,
            isShowRemoveModal: false,
            toggleableColumns: [],
            isShowColumnsSwitch: false,
            ownerSitesOptions: [],
            partnerOrganizationOptions: [],
            partnerSiteOptions: [],
            organizationsData: {},
            payerOptions: [DEFAULT_OPTION, 'consignor', 'consignee', 'third party'],
            dateFields: ['updated_at', 'created_at'],
            isAllRowsValid: false,
            isNewRowEmpty: true,
            isLoadingNew: false,
            isLoading: false,
        };
    },
    computed: {
        user() {
            return this.$store.getters['auth/user'];
        },
        isGodUser() {
            return this.$store.getters['auth/isGodUser'];
        },
        isAllowedToEdit() {
            return this.isGodUser;
        },
        organizationId() {
            return this.$route.params.id;
        },
    },
    methods: {
        getIsValidRow(row) {
            return validateRow(row);
        },
        getIsEmptyValue(value) {
            return !value || value === DEFAULT_OPTION;
        },
        async selectPartnerOrganization(row, value) {
            await this.$nextTick();
            row.partner_organization_key = value;
            this.partnerSiteOptions = Object.values(this.organizationsData).find(option => option.key === value)?.sites.map(site => site.key);
            this.updateHasChanges();
        },
        async selectPartnerSite(row, value) {
            await this.$nextTick();
            row.partner_site_key = value;
            this.updateHasChanges();
        },
        hasLength() {
            return this.rowsLength > 1;
        },
        setPartnerSiteOptions() {
            const rows = this.$refs[this.tableRef].table?.rows;

            rows.forEach(row => {
                if (!row.partner_organization_key || row.partner_organization_key === DEFAULT_OPTION) {
                    return;
                }

                row.partner_site_options = [DEFAULT_OPTION].concat(
                    this.partnerOrganizationOptions
                        .find(option => option.key === row.partner_organization_key)
                );
            });
        },
        getIsAutocompleteField(fieldName) {
            return AUTOCOMPLETE_FIELD_NAMES.find(name => fieldName.indexOf(name) !== -1);
        },
        rowsLoadedHandler(rows) {
            this.onRowsLoaded(rows, row => row.is_active = Number(row.is_active), rowsLoadedPostCallback);
        },
        rowsLoadedPostCallback() {
            this.setPartnerSiteOptions();
            this.updateIsAllRowsValid();
            this.updateIsNewRowEmpty();
        },
        setOwnerSitesOptions() {
            this.ownerSitesOptions = [DEFAULT_OPTION].concat(this.organization.sites.map(site => site.key));
        },
        async chooseOwner(owner, row) {
            row.owner_from_source = owner;
            row.owner_site_from_source = '';

            const matchOrganization = Object.values(this.organizationsData).find(organization => organization.name === row[key]);
            if (!matchOrganization) {
                return;
            }

            if (this.ownerSitesOptions[matchOrganization.name]) {
                return;
            }

            const { data: { data } } = await axios.get(this.$apiUrl.sites.base + `?filter[organization_id]=${matchOrganization.id}`);
            this.ownerSitesOptions[matchOrganization.name] = data.map(item => item.name);
        },
        filterOptionsUrl({role, field}) {
            return (value) => {
                return this.$apiUrl.addressBook.filterOptions
                    + `?filter[organization_id]=${this.organizationId}`
                    + `&field=${field}`
                    + `&role=${role}`
                    + `&value=${value}`;
            };
        },
        formatDate(date) {
            if (!date) {
                return;
            }

            return moment(date).format('YYYY-MM-DD');
        },
        addEmptyRow() {
            const rows = this.$refs[this.tableRef].table?.rows;

            this.$refs[this.tableRef].table?.rows.push({
                ...DEFAULT_ROW,
                owner_from_source: this.organizationKey,
                owner_site_from_source: !rows.length ? this.ownerSitesOptions[0] : null,
                priority: rows[rows.length - 1] ? rows[rows.length - 1].priority + 1 : 1,
            });

            this.hasNewRules = true;
            this.rowsLength = this.$refs[this.tableRef].table?.rows.length;
        },
        async recalculate() {
            try {
                await axios.post(this.$apiUrl.consignmentImports.rules.share.recalculate + `/${this.organizationId}`);

                this.$snotify.success('Recalculation complete');
            } catch(error) {
                this.$snotify.error(requestErrors(error));
            }
        },
        preparePayload(row) {
            if (!row) {
                return;
            }

            return { id: row.id, payload: preparePayloadRow(row) };
        },
        async saveNewRule() {
            this.isLoadingNew = true;

            const newRule = this.$refs[this.tableRef].table?.rows
                .find(row => !row.id)

            try {
                await axios.post(this.endpoint, preparePayloadRow(newRule));

                this.$refs[this.tableRef].table?.fetchRowsSilent();

                this.$snotify.success('New rule creted');
                this.hasNewRules = false;
                this.isNewRowEmpty = true;
            } catch (error) {
                this.$snotify.error(requestErrors(error));
            }

            this.isLoadingNew = false;
        },
        async updateHasChanges() {
            await this.$nextTick();
            this.hasChanges = !!this.$refs[this.tableRef].table.rows.find(tableRow => {
                if (!tableRow.id || !tableRow.owner_from_source) {
                    return false;
                }

                const match = this.initialRows.find(initialRow => tableRow.id === initialRow.id);

                return Object.keys(tableRow).find(key => tableRow.id && (tableRow[key] !== match[key]));
            });

            this.updateIsAllRowsValid();
            this.updateIsNewRowEmpty();
        },
        updateIsAllRowsValid() {
            this.isAllRowsValid = this.$refs[this.tableRef]?.table.rows.every(row => this.getIsValidRow(row));
        },
        updateIsNewRowEmpty() {
            const newRow = this.$refs[this.tableRef]?.table.rows.find(row => !row.id);
            if (!newRow) {
                return true;
            }

            this.isNewRowEmpty = !this.getIsValidRow(newRow);
        },
        async saveChanged() {
            this.isLoading = true;

            const changedRows = this.getChangedRows();

            if (!changedRows.length) {
                this.$snotify.error('No rules to update');

                return;
            }

            const promises = [];
            changedRows.forEach(row => {
                const {id, payload} = this.preparePayload(row);
                promises.push(axios.post(this.endpoint + `/${id}`, payload));
            });

            try {
                await Promise.allSettled(promises);

                this.$refs[this.tableRef].table?.fetchRowsSilent();
                this.hasNewRules = false;

                this.$snotify.success('Rule(s) updated');
            } catch (error) {
                this.$snotify.error(requestErrors(error));
            }

            this.isLoading = false;
        },
        openRemoveModal(id) {
            this.currentRemoveId = id;
            this.isShowRemoveModal = true;
        },
        closeRemoveModal() {
            this.currentRemoveId = null;
            this.isShowRemoveModal = false;
        },
        async removeRule() {
            this.isLoading = true;
            this.isLoadingNew = true;

            if (!this.currentRemoveId) {
                const rows = this.$refs[this.tableRef].table.rows;
                const toDeleteIndex = rows.findIndex(row => !row.id);
                this.$refs[this.tableRef].table.rows.splice(toDeleteIndex);

                this.rowsLength = this.$refs[this.tableRef].table?.rows.length;
                this.hasNewRules = false;
                this.isShowRemoveModal = false;

                return;
            }

            try {
                await axios.delete(this.endpoint + `/${this.currentRemoveId}`);

                this.$refs[this.tableRef].table?.fetchRows();
            } catch(error) {
                this.$snotify.error(requestErrors(error));
            }

            this.isShowRemoveModal = false;
            this.currentRemoveId = null;
            this.isLoading = false;
            this.isLoadingNew = false;
        },
        setSites(sites) {
            sites.forEach(site => {
            const organizationId = site.organization?.id || site.organization_id;
                if (!(organizationId in this.organizationsData)) {
                    this.organizationsData[organizationId] = { ...site.organization, sites: [] };
                }

                const { id, name, key } = site;
                this.organizationsData[organizationId].sites.push({ id, name, key });
            });

            this.partnerOrganizationOptions = [DEFAULT_OPTION].concat(Object.values(this.organizationsData).map(organization => organization.key));
        },
        async fetchSites() {
            try {
                const { data: { data } } = await axios.get(`${this.$apiUrl.sites.base}?pageSize=10000`);
                this.setSites(data);
            } catch (error) {
                this.$snotify.error(requestErrors(error));
            }
        },
        setToggleableColumns() {
            const untoggleableColumnsIndexes = [];
            const tableToggleableColumns = this.$refs[this.tableRef].table?.toggleableColumns;

            UNTOGGLEABLE_COLUMNS_KEYS.forEach(name => {
                const index = tableToggleableColumns.findIndex(col => col.key === name);

                if (index === -1) {
                    return;
                }

                untoggleableColumnsIndexes.push(index);
            });

            this.toggleableColumns = tableToggleableColumns;
            untoggleableColumnsIndexes
                .sort((a, b) => a > b ? -1 : 1) //sort indexes desc
                .forEach(colIndex => {
                this.toggleableColumns.splice(colIndex, 1);
                this.toggleableColumns.map(col => col.key);
            });
        },
    },
    async created() {
        this.tableRef = 'consignments-sharing';
        await this.fetchSites();
        this.setOwnerSitesOptions();
    },
    mounted() {
        this.setToggleableColumns();
    },
}
</script>
